import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7049cbce"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1,
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_JDCHeader = _resolveComponent("JDCHeader");
  const _component_Breadcrumb = _resolveComponent("Breadcrumb");
  const _component_router_view = _resolveComponent("router-view");
  const _component_JDCFooter = _resolveComponent("JDCFooter");
  const _component_EnFooter = _resolveComponent("EnFooter");
  const _component_BackTopOrBottom = _resolveComponent("BackTopOrBottom");
  const _component_CompleteInfo = _resolveComponent("CompleteInfo");
  const _component_LatestUserMedalDialog = _resolveComponent("LatestUserMedalDialog");
  const _component_CookiePolicy = _resolveComponent("CookiePolicy");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: $data.locale
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      id: "refactor-jdc",
      style: {
        "font-family": "HarmonyOS_Sans_SC_Regular, Arial, 'Microsoft YaHei', '微软雅黑', sans-serif !important"
      },
      onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => $options.headerSearchClose && $options.headerSearchClose(...args), ["stop"]))
    }, [$data.isShow && $data.tokenInfo.isToken ? (_openBlock(), _createBlock(_component_JDCHeader, {
      key: 0,
      ref: "header"
    }, null, 512)) : _createCommentVNode("", true), $data.tokenInfo.isToken ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Breadcrumb, {
      class: "jdc-breadcrumb",
      limit: 25
    }), _createVNode(_component_router_view)])) : _createCommentVNode("", true), $data.isShow && $data.tokenInfo.isToken && !$data.IS_EN_JDC ? (_openBlock(), _createBlock(_component_JDCFooter, {
      key: 2
    })) : _createCommentVNode("", true), $data.isShow && $data.tokenInfo.isToken && $data.IS_EN_JDC && $data.showFooter ? (_openBlock(), _createBlock(_component_EnFooter, {
      key: 3,
      class: "en-footer",
      "about-us": $data.enAboutUs,
      "outer-links": $data.enOuterLinks,
      "logo-img": $data.logoImg,
      mail: "helponline@huawei.com"
    }, null, 8, ["about-us", "outer-links", "logo-img"])) : _createCommentVNode("", true), $options.isShowBackTop ? (_openBlock(), _createBlock(_component_BackTopOrBottom, {
      key: 4
    })) : _createCommentVNode("", true), $data.completeInfoShow ? (_openBlock(), _createBlock(_component_CompleteInfo, {
      key: 5,
      "complete-info-show": $data.completeInfoShow
    }, null, 8, ["complete-info-show"])) : _createCommentVNode("", true), _createVNode(_component_LatestUserMedalDialog), _createVNode(_component_CookiePolicy)])]),
    _: 1
  }, 8, ["locale"]);
}