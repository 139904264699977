// 海外版路由
import RouterUtils from '@/utils/common/RouterUtils';

const RefactorNewThread = () => import('@/components/refactorJdc/newThread/newThread.vue');
const RefactorViewForum = () => import('@/components/refactorJdc/forum/forum.vue');
const RefactorForumManage = () => import('@/components/refactorJdc/circleManage/Common.vue');
const RefactorViewthread = () => import('@/components/refactorJdc/postDetails/postDetails.vue');
const RefactorShield = () => import('@/components/refactorJdc/common/Shield.vue');
const EnPortal = () => import('@/components/enJdc/Portal/Portal.vue');
const RefactorPostList = () => import('@/components/refactorJdc/profile/PostList.vue');
const RefactorMySmartBean = () => import('@/components/refactorJdc/profile/MySmartBean');
const RefactorPrivacyStatement = () => import('@/components/refactorJdc/common/PrivacyStatement');
const RefactorActivity = () => import('@/components/refactorJdc/activity/Activity.vue');
const RefactorSettings = () => import('@/components/refactorJdc/settings/settings.vue');
const RefactorProfile = () => import('@/components/refactorJdc/profile/Profile.vue');
const RefactorCookiePolicy = () => import('@/components/common/cookiePolicy/CookiePolicyDetail'); // 海外cookie
const OverSeasCompleteInfo = () => import('@/components/refactorJdc/common/OverSeasCompleteInfo.vue'); // 海外完善用户信息
const RefactorLottery = () => import('@/components/common/lottery/lottery/refactorLottery');
const RefactorContactList = () => import('@/components/refactorJdc/profile/ContactList.vue');
const Login = () => import('@/components/enJdc/login');

// needLogin 当前页面是否需要登录
const enJdcRoutes = [
  {
    path: RouterUtils.baseRoute('/refactor/newThread'),
    name: 'NewThread',
    component: RefactorNewThread,
    meta: { needLogin: true, pageCode: 'P4769771051A2EA' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/viewForum'),
    name: 'viewForum',
    component: RefactorViewForum,
    meta: {
      needLogin: false,
      pageCodes: {
        flag: {
          default: 'P4032B2A69391C7',
          suggest: 'P539AFAB57C1355', // 提产品建议
        },
      },
    },
  },
  {
    path: RouterUtils.baseRoute('/refactor/forumManage'),
    name: 'ForumManage',
    component: RefactorForumManage,
    meta: { needLogin: false, pageCode: 'P4074CAD2FF2BFD' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/viewthread'),
    name: 'Viewthread',
    component: RefactorViewthread,
    meta: { needLogin: false, pageCode: 'P476984CFDC0841' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/shield'),
    name: 'Shield',
    component: RefactorShield,
    meta: { needLogin: false, pageCode: 'P476A10A077FB52' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/portal'),
    name: 'EnPortal',
    component: EnPortal,
    meta: { needLogin: false, pageCode: 'P4769EBB97A298B' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile/thread'),
    name: 'PostList',
    component: RefactorPostList,
    meta: { needLogin: true, pageCode: 'P681B0075A465BF' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile/credit'),
    name: 'MySmartBean',
    component: RefactorMySmartBean,
    meta: { needLogin: false, pageCode: '' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/privacyStatement'),
    name: 'PrivacyStatement',
    component: RefactorPrivacyStatement,
    meta: { needLogin: false, pageCode: 'P4032E66B9AB33D' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/settings'),
    name: 'Settings',
    component: RefactorSettings,
    meta: { needLogin: true, pageCode: 'P4032EEFD59D2C8' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile'),
    name: 'Profile',
    component: RefactorProfile,
    meta: { needLogin: true, pageCode: '' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile/contact'),
    name: 'ContactList',
    component: RefactorContactList,
    meta: { needLogin: true, pageCode: '' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/activity'),
    name: 'Activity',
    component: RefactorActivity,
    meta: { needLogin: false, pageCode: 'P73B72CD17EED76' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/cookiePolicy'),
    name: 'CookiePolicy',
    component: RefactorCookiePolicy,
    meta: { needLogin: false, pageCode: 'P73B73491BB72CA' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/overSeasComplete'),
    name: 'OverSeasComplete',
    component: OverSeasCompleteInfo,
    meta: { needLogin: true, pageCode: 'P4024BCF43B67C7' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/lottery/:id'),
    name: 'Lottery',
    component: RefactorLottery,
    meta: { needLogin: false, pageCode: 'P74E359BFEB3BA5' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/login'),
    name: 'login',
    component: Login,
    meta: { needLogin: false, pageCode: 'P69D6180E63640C' },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/refactor/portal',
    meta: { needLogin: false },
  },
];

export default enJdcRoutes;
