import env from '@/config/env.js';
let baseQRCodeUrl = '';
if (env.isProd) {
  baseQRCodeUrl = require('@/assets/images/wxmini.png');
} else {
  baseQRCodeUrl = require('@/assets/images/wxmini_test.png');
}
export default {
  data() {
    return {
      active: false,
      qrcodeUrl: '',
      loading: false
    };
  },
  watch: {
    $route() {
      // 地址变化的时候，小程序码清除, 后续重新获取
      this.qrcodeUrl = '';
    }
  },
  methods: {
    handleImgError() {
      this.qrcodeUrl = baseQRCodeUrl;
    },
    handlePopoverShow() {
      this.active = true;
      if (this.loading || this.qrcodeUrl) {
        return;
      }
      this.loading = true;
      const {
        params = {},
        page: pagename
      } = this.$store.state.common.WEChatPageCode;
      const scence = Object.keys(params || {}).map(item => {
        return `${item}=${params[item]}`;
      }).join('&') || '&';
      this.$rm.api.GET(this.$rm.api.refactorApi.user.getCurrentQrcode, {
        url: pagename,
        scence
      }, env.newApiBaseURL, 'blob').then(({
        data
      }) => {
        const blob = new Blob([data], {
          type: 'images/png'
        });
        this.qrcodeUrl = (window.URL || window.webkitURL).createObjectURL(blob);
      }).catch(() => {
        this.qrcodeUrl = baseQRCodeUrl;
      }).finally(() => {
        this.loading = false;
      });
    },
    handlePopoverHide() {
      this.active = false;
    }
  }
};