import store from '@/store/index';
import { IS_EN_JDC, isBuriedPageCode } from '@/utils/web/constant';

/**
 * 国内埋码
 */
const zhCode = {
  Activity: 'P26E754DC429BF1',
  Certified: 'PA5E606D660128E',
  Chat: 'PC66D2293361580',
  ColumnDetail: 'PC66C311978F54E',
  Column: 'PDD40E63BDBE984',
  ColumnPost: 'PA5E5FA66D96A3B',
  Group: 'PDD40C22CAF2A29',
  Mall: 'PDD40E1B73E0799',
  Search: 'PC66C1A020FC53E',
  Trade: 'PC66C2AC719A970',
  NewThread: 'PC66C3458C0ACEF',
  Portal: 'PDD40A17A3BEE7B',
  Viewthread: 'PA293B5C4B0281A',
  ContactList: 'PA5E5FE45960FD6',
  MyLevel: 'PC66CA586E1E006',
  MySmartBean: 'PC66CA0CDE99C6C',
  MyTask: 'PC66CA361AC7F90',
  PostList: 'PC66C9CCE359228',
  Profile: 'PC66C3F7388897C',
  Sign: 'PC66CA8531B37A0',
  FindUser: {
    other: 'PC66C3AA7DF24BE',
    user: 'PA5E5F433C34A22',
  },
  viewForum: {
    internal: 'PDD40DBED224B13',
    circle: 'PA9399C8A27BD57',
    plate: 'PDD40D40B741DFD',
  },
  Notice: {
    thread: 'PC66D32190700F8',
    notify: 'PC66D7F74A7065A',
  },
  Settings: {
    perProfile: {
      base: 'PC66C53EAF49AAF',
      contact: 'PC66C57F9232077',
    },
    avatar: 'PC66C760AD5F43D',
    privacySecurity: {
      subscription: 'PC66C941992ABD8',
      other: 'PC66C98C03F9ADF',
    },
  },
  Cross: 'P45E0D0B2CA6236',
};
/**
 * 海外埋码
 */
const enCode = {
  Activity: 'P5D4DC3DDD79E44',
  Settings: {
    perProfile: {
      base: 'P9E1333BB522D55',
      contact: 'P47B95540BF976B',
    },
    avatar: 'P798CCB0AA5B881',
    privacySecurity: {
      subscription: 'P47B9635A592984',
      other: 'P798CDC3C9BD727',
    },
  },
  viewForum: {
    plate: 'P74066A5D7E3715',
    circle: 'P539AFAB57C1355',
  },
};
// 判断是海外版还是国内版埋码
const pageCode = IS_EN_JDC ? enCode : zhCode;
export default {
  ...pageCode,
  hwa(code) {
    const { baseInfo = {}, userInfoGet } = store?.state?.user || {};
    if (isBuriedPageCode()) {
      window.hwa('trackPageView', code);
    }
    if (userInfoGet && !baseInfo.uid) {
      store.dispatch('buryCode', code);
    }
  },
};
