import Html from './HtmlUtils.js';

import baseApi from '@/axios/baseApi.js';
import refactorApi from '@/axios/refactorApi.js';
import env from '@/config/env.js';
import errCode from '@/utils/common/errCode.js';

function result(list, item, baseArr, type) {
  const newBaseArr = unique(baseArr);
  const listItemArr = newBaseArr.filter(i => item[type] && item[type].includes(i.key));
  if (listItemArr.length > 1) {
    const map = {};
    item[type].split(',').forEach((val, index) => {
      map[val] = index;
    });
    listItemArr.forEach(li => {
      item[`imgurl${map[li.key]}`] = Html.handleImage(li.base64Image);
    });
  } else {
    item.imgurl = listItemArr[0] && listItemArr[0].base64Image ? Html.handleImage(listItemArr[0].base64Image) : '';
  }
}

function unique(arr) {
  const map = new Map();
  for (const item of arr) {
    if (!map.has(item.key)) {
      map.set(item.key, item);
    }
  }
  return [...map.values()];
}

const imageType = {
  jpg: 'jpeg',
};

function getFileName(disposition) {
  if (!disposition) {
    return '';
  }
  const imageMatch = disposition.match(/fileName=([^;]+)/);
  if (imageMatch && imageMatch.length) {
    return imageMatch[1] || '';
  }
  return '';
}

function getImageTypeFromDisposition(disposition) {
  if (!disposition) {
    return '';
  }
  const fileName = getFileName(disposition);
  if (fileName) {
    const type = fileName.split('.').pop().toLowerCase();
    return imageType[type] || type;
  }

  return '';
}

export default {
  getFileName,
  getImageTypeFromDisposition,
  downloadImage(uuid) {
    const params = {
      key: uuid,
    };
    const url = refactorApi.user.downloadImage;
    return baseApi
      .GET(url, params, env.newApiBaseURL)
      .then(res => {
        if (res) {
          return Html.handleImage(res);
        }
        return '';
      })
      .catch(() => {});
  },
  /**
   * 批量根据uuid获取base64图片
   * 最多获取10张图片的处理结果
   * @param list 图片数组
   * @param imgField 图片字段
   */
  async handleMultiBase64Img(list, imgField) {
    const imgMap = {};
    const params = [];
    list.forEach((item, idx) => {
      const key = item[imgField];
      // 空的key会报参数错误，这里做一下判断过滤
      if (key) {
        if (imgMap[key]) {
          imgMap[key].push(idx);
        } else {
          imgMap[key] = [idx];
        }
        params.push(key);
      }
    });
    if (!params.length) {
      return;
    }
    const url = refactorApi.user.downloadImages;
    const { code, data } = await baseApi.POST(url, params, env.newApiBaseURL).catch(() => ({}));
    if (code === errCode.success && Array.isArray(data)) {
      data.forEach(item => {
        const idxArr = imgMap[item.key];
        if (!Array.isArray(idxArr)) {
          return;
        }
        idxArr.forEach(idx => {
          list[idx][imgField] = Html.handleImage(item.base64Image);
        });
      });
    }
  },
  /**
   * 批量处理list中的图片字段，接口限制每次最多10张，如果超出就需要分片
   * @param list 图片数组
   * @param imgField 图片字段
   * @param chunkSize 每次下载数量
   */
  async batchTransferBase64Img(list, imgField, chunkSize) {
    if (!list.length) {
      return;
    }
    const MAX_CHUNK_SIZE = 10;
    const chunk = chunkSize ? Math.min(chunkSize, MAX_CHUNK_SIZE) : MAX_CHUNK_SIZE;
    if (list.length <= chunk) {
      await this.handleMultiBase64Img(list, imgField);
      return;
    }

    const promiseArr = [];
    const count = Math.ceil(list.length / chunk);
    for (let i = 0; i < count; i++) {
      const sliceList = list.slice(i * chunk, (i + 1) * chunk);
      promiseArr.push(this.handleMultiBase64Img(sliceList, imgField));
    }
    await Promise.all(promiseArr);
  },
  /**
   * 将图片uuid转为base64编码
   * @param uuid 图片uuid
   * @returns {string}
   */
  async transferBase64Img(uuid) {
    const url = refactorApi.user.downloadImage;
    const params = {
      key: uuid,
    };
    const res = await baseApi.GET(url, params, env.newApiBaseURL).catch(() => '');
    return res ? Html.handleImage(res) : '';
  },
  /**
   * 将图片转为base64编码
   * @param {file} file 图片对象
   * @returns {Promise(string)} data:image/jpeg;base64,...
   */
  transferImg2Base64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise(resolve => {
      reader.onload = res => {
        resolve(res.target.result);
      };
    });
  },
  /**
   * 批量下载图片
   *
   * @param list 图片数组
   * @param type 字段
   * @param baseCount 批量下载数量
   */
  handleList(list, type, baseCount) {
    if (!Array.isArray(list) || !list.length) {
      return;
    }
    const listData = JSON.parse(JSON.stringify(list));
    const count = listData.length / (baseCount || 10);
    for (let i = 0; i <= parseInt(count); i++) {
      this.getImage(list.slice(i * (baseCount || 10), (i + 1) * (baseCount || 10)), type);
    }
  },
  getImage(list, type) {
    const params = [];
    JSON.parse(JSON.stringify(list))
      .filter(item => item[type])
      .forEach(item => {
        if (item[type].includes(',')) {
          params.push.apply(params, item[type].split(','));
        } else {
          params.push(item[type] || '');
        }
      });
    if (!params.length) {
      return;
    }
    const url = refactorApi.user.downloadImages;
    baseApi.POST(url, params, env.newApiBaseURL).then(res => {
      if (res.code === errCode.success) {
        const baseArr = res.data || [];
        list.forEach(item => {
          result(list, item, baseArr, type);
        });
      }
    });
  },
  /**
   * 获取图片文件流
   * @param {object} item
   * @param {string} imgField
   */
  getImgBlob(item, imgField) {
    if (!item || !item[imgField]) {
      return;
    }
    const apiUrl = refactorApi.user.attachmentDownload;
    const params = { id: item[imgField], isCommon: true };
    item[imgField] = '';
    baseApi.GET(apiUrl, params, env.newApiBaseURL, 'blob').then(res => {
      const { data, headers } = res;
      const disposition = headers['content-disposition'];
      // 如果有返回的文件类型，获取当前返回的文件的文件类型并进行初始化
      const imgType = getImageTypeFromDisposition(disposition);
      const blobConfig = {};
      if (imgType) {
        blobConfig.type = `image/${imgType}`;
      }
      const blob = new Blob([data], blobConfig);
      item[imgField] = window.URL.createObjectURL(blob);
    });
  },
  /**
   * 释放图片引用
   * @param {object} val
   * @param {string} key
   */
  revokeImg(val, key) {
    window.URL.revokeObjectURL(val[key]);
  },
  // 根据uid获取用户头像
  getBase64AvatarFree(uid) {
    return uid ? `${env.newApiBaseURL}${refactorApi.user.getBase64AvatarFree}?uid=${uid}&isApp=false` : '';
  },
  /**
   * 批量处理list中的图片字段，使用用户id拼接图片url
   * @param list 图片数组
   * @param imgField 图片字段
   * @param uidField 用户id字段
   */
  batchSetListImgUrl(list, imgField, uidField) {
    if (!list.length) {
      return;
    }
    list.forEach(item => {
      item[imgField] = item[uidField] ? this.getBase64AvatarFree(item[uidField]) : '';
    });
  },
  /**
   * 根据id下载
   * @param {string} id 图片id
   * @param {boolean} isCommon 是否运营后台上传的图片
   * */
  getImgUrlById(id, isCommon = false) {
    if (!id) {
      return '';
    }
    let paramString = `id=${encodeURIComponent(id)}`;
    if (isCommon) {
      paramString += '&isCommon=true';
    }
    return `${env.newApiBaseURL}${refactorApi.user.attachmentDownload}?${paramString}`;
  },
  /**
   * base64转文件
   * @param base64URL base64URL
   * @param name 文件名
   * @return {File} 返回文件
   */
  getFileFromBase64(base64URL, name) {
    const arr = base64URL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bStr = atob(arr[1]);
    let num = bStr.length;
    const u8arr = new Uint8Array(num);
    while (num--) {
      u8arr[num] = bStr.charCodeAt(num);
    }
    return new File([u8arr], name, { type: mime });
  },
};
