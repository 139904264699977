import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-57815c82"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "back-top-bottom"
};
const _hoisted_2 = {
  class: "back-wrapper"
};
const _hoisted_3 = ["title"];
const _hoisted_4 = {
  class: "back-wrapper"
};
const _hoisted_5 = ["title"];
import { onUnmounted, onMounted } from 'vue';
import common from '@/utils/common/common';
export default {
  __name: 'BackTopOrBottom',
  setup(__props) {
    const distance = 500;

    // 判断回到顶部底部按钮显示与否
    const backBtnShow = async () => {
      await common.stopClick('scrollTopOrBottom', 50);
      const backToTopButton = document.getElementById('backToTop');
      const backToBottomButton = document.getElementById('backToBottom');
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > distance) {
        backToTopButton.classList.add('back-icon-show');
        backToTopButton.classList.remove('back-icon-hide');
      } else {
        backToTopButton.classList.add('back-icon-hide');
        backToTopButton.classList.remove('back-icon-show');
      }
      if (scrollTop > scrollHeight - clientHeight - distance || scrollHeight - clientHeight < distance) {
        backToBottomButton.classList.add('back-icon-hide');
        backToBottomButton.classList.remove('back-icon-show');
      } else {
        backToBottomButton.classList.add('back-icon-show');
        backToBottomButton.classList.remove('back-icon-hide');
      }
    };
    onMounted(() => {
      // 监听滚动条滚动
      window.addEventListener('scroll', backBtnShow);

      // 初始界面时判断是否显示滚动到底部按钮
      let timer = null;
      timer = setTimeout(() => {
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        const backToBottomButton = document.getElementById('backToBottom');
        if (scrollHeight - clientHeight > distance) {
          backToBottomButton.classList.add('back-icon-show');
        }
        clearTimeout(timer);
        timer = null;
      }, 3000);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', backBtnShow);
    });

    // 回到顶部
    const goTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    // 滚动到底部
    const goBottom = () => {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const toTop = scrollHeight - clientHeight;
      window.scrollTo({
        top: toTop,
        behavior: 'smooth'
      });
    };
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        id: "backToTop",
        class: "icon-wrapper back-top",
        title: _ctx.$t('lang.common.goTop'),
        onClick: goTop
      }, [_createVNode(_component_svg_icon, {
        icon: "icon_scroll",
        class: "icon-scroll"
      })], 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        id: "backToBottom",
        class: "icon-wrapper back-bottom",
        title: _ctx.$t('lang.common.goBottom'),
        onClick: goBottom
      }, [_createVNode(_component_svg_icon, {
        icon: "icon_scroll",
        class: "icon-scroll"
      })], 8, _hoisted_5)])]);
    };
  }
};