import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d25d9fa0"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-hot-text", "href"];
const _hoisted_2 = {
  key: 1,
  class: "textTitle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon_arrow_down = _resolveComponent("el-icon-arrow-down");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menuList", {
      'en-menu-list': _ctx.isEnJdc
    }])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.searchMenus, (item, index) => {
    return _openBlock(), _createElementBlock("a", {
      key: index,
      class: _normalizeClass(["menuItem", {
        selected: $options.isCurrentMenuSelected(item.path, item.activeSign),
        hot: item.isHot
      }]),
      "data-hot-text": item.isNew ? 'NEW' : '热门',
      href: $options.getJumpRoute(item.path)
    }, [item.children ? (_openBlock(), _createBlock(_component_el_dropdown, {
      key: 0,
      placement: "bottom-start",
      "popper-class": "header-dropdown",
      onCommand: $options.subMenuClick
    }, {
      dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, {
        class: "_header_dropdown_common"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child, idx) => {
          return _openBlock(), _createBlock(_component_el_dropdown_item, {
            key: idx,
            command: child.path
          }, {
            default: _withCtx(() => [_createElementVNode("a", {
              class: _normalizeClass({
                selected: $options.isForumMenuActive(child.activeSign)
              })
            }, _toDisplayString(child.name), 3)]),
            _: 2
          }, 1032, ["command"]);
        }), 128))]),
        _: 2
      }, 1024)]),
      default: _withCtx(() => [_createElementVNode("a", {
        class: _normalizeClass(["textTitle", {
          selected: $options.isForumActive(item.children)
        }])
      }, [_createTextVNode(_toDisplayString(item.name) + " ", 1), _createVNode(_component_el_icon, {
        class: "menuMoreIcon"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon_arrow_down)]),
        _: 1
      })], 2)]),
      _: 2
    }, 1032, ["onCommand"])) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.name), 1))], 10, _hoisted_1);
  }), 128))], 2);
}