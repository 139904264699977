import env from '@/config/env';
import i18n from '@/i18n/i18n';
const { t } = i18n.global;
/**
 * 特殊圈子id
 * discuss 精华讨论
 * suggest 提产品建议
 * interactionsuggest 互动讨论
 * default 活动
 * bean 智豆兑换
 */
const ALPHA_CONFIG = Object.freeze({
  discuss: '200809',
  suggest: '1293',
  interactionsuggest: '200823',
  bean: '200018',
  default: '2614',
  station: '2548',
  askpeers: '202807',
  industryReports: '203271', // 行业报告
  openClass: '200686', // 公开课
  originalReport: '200688', // 原创报告
});

const BETA_CONFIG = Object.freeze({
  discuss: '200499',
  suggest: '200501',
  interactionsuggest: '200519',
  bean: '200018',
  default: '2614',
  station: '2548',
  askpeers: '201027',
  industryReports: '203271', // 行业报告
  openClass: '200686', // 公开课
  originalReport: '200688', // 原创报告
});

const ALL_FIDS = {
  dev: ALPHA_CONFIG,
  sit: ALPHA_CONFIG,
  dev2: ALPHA_CONFIG,
  icsl: ALPHA_CONFIG,
  icslDev: ALPHA_CONFIG,
  alpha: ALPHA_CONFIG,
  beta: BETA_CONFIG,
  uat2: BETA_CONFIG,
  prod: Object.freeze({
    discuss: '200633',
    suggest: '200029',
    interactionsuggest: '200641',
    bean: '1225',
    default: '200515',
    station: '200061',
    askpeers: '200676',
    industryReports: '200687', // 行业报告
    openClass: '200686', // 公开课
    originalReport: '200688', // 原创报告
  }),
};

const APPRECIATE_TYPES = {
  topic: 1,
  post: 2,
  comment: 3,
};

// 用户资源池测试用户uid
const AUTHORIZED_DMP_USERS = [31, 10, 463021, 3692168, 3044551, 2849495, 2847737, 2990633, 2931117];

const getFlagId = flag => {
  const envFids = ALL_FIDS[env.currentEnv] || ALL_FIDS.prod;
  return envFids[flag] || envFids.default;
};

// 用户资源池跳转活动页，活动分类id
const BANNER_TYPE_OBJ = {
  dev2: 213,
  icslDev: 56,
  uat2: 73,
  prod: 56,
};
const bannerTypeId = BANNER_TYPE_OBJ[env.currentEnv] || BANNER_TYPE_OBJ.dev2;
// 用户资源池白名单用户组id
const DMP_GROUP_OBJ = {
  dev2: 281,
  icslDev: 56,
  uat2: 271,
  prod: 324,
};
const dmpGroupId = DMP_GROUP_OBJ[env.currentEnv] || DMP_GROUP_OBJ.dev2;

const PROVINCE_LIST = [
  '北京',
  '天津',
  '上海',
  '重庆',
  '内蒙古',
  '新疆',
  '西藏',
  '宁夏',
  '广西',
  '香港',
  '澳门',
  '黑龙江',
  '吉林',
  '辽宁',
  '河北',
  '山西',
  '青海',
  '山东',
  '河南',
  '江苏',
  '安徽',
  '浙江',
  '福建',
  '江西',
  '湖南',
  '湖北',
  '广东',
  '台湾',
  '海南',
  '甘肃',
  '陕西',
  '四川',
  '贵州',
  '云南',
];
const BIGV_LIST = [
  { name: '技术大牛', dev2: 255, uat2: 255, prod: 271 },
  { name: '行业领袖', dev2: 256, uat2: 256, prod: 273 },
  { name: '知识达人', dev2: 257, uat2: 257, prod: 275 },
];
const description = {
  discuss: {
    title: t('lang.keyWords.discussTitle'),
    description: t('lang.keyWords.discussDescription'),
    keywords: t('lang.keyWords.discussKeywords'),
  },
  interactionsuggest: {
    title: t('lang.keyWords.interactionTitle'),
    description: t('lang.keyWords.interactionDescription'),
    keywords: t('lang.keyWords.interactionKeywords'),
  },
  default: {
    title: t('lang.keyWords.defaultTitle'),
    description: t('lang.keyWords.defaultDescription'),
    keywords: t('lang.keyWords.defaultKeywords'),
  },
  askpeers: {
    title: t('lang.keyWords.askpeersTitle'),
    description: t('lang.keyWords.askpeersDescription'),
    keywords: t('lang.keyWords.askpeersKeywords'),
  },
  openClass: {
    title: t('lang.keyWords.openClassTitle'),
    description: t('lang.keyWords.openClassDescription'),
    keywords: t('lang.keyWords.openClassKeywords'),
  },
  industryReports: {
    title: t('lang.keyWords.industryReportsTitle'),
    description: t('lang.keyWords.industryReportsDescription'),
    keywords: t('lang.keyWords.industryReportsKeywords'),
  },
};
/**
 * 通过当前页面的fid获取对应板块的关键词
 * @param {fid}  当前页面的fid
 * @returns obj
 */
const getKeyWords = fid => {
  const envFids = ALL_FIDS[env.currentEnv] || ALL_FIDS.prod;
  const inverted = {};
  Object.keys(envFids).forEach(key => {
    inverted[envFids[key]] = key;
  });
  return description[inverted[fid]];
};
export { getFlagId, ALL_FIDS, APPRECIATE_TYPES, AUTHORIZED_DMP_USERS, bannerTypeId, dmpGroupId, PROVINCE_LIST, BIGV_LIST, getKeyWords };
