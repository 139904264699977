import LoginBlock from '@/components/common/LoginBlock.vue';
import MenuList from '@/components/refactorJdc/header/MenuList.vue';
import MobileTag from '@/components/refactorJdc/header/MobileTag.vue';
import SearchBlock from '@/components/refactorJdc/header/SearchBlock.vue';
import { isMobile } from '@/utils/common/CommonUtils';
import jumpUniportal from '@/utils/common/isInnerIP';
import APPUtil from '@/utils/h5/APPUtil';
import { IS_EN_JDC } from '@/utils/web/constant';

// sessionStorage 是否第一次从web跳转h5标识
const APP_LINK_BACK_FLAG = 'app-to-link-turn-back';
export default {
  name: 'HeaderJDC',
  components: {
    SearchBlock,
    MenuList,
    MobileTag,
    LoginBlock
  },
  props: {
    isError: Boolean
  },
  data() {
    return {
      IS_EN_JDC,
      searchOpen: false,
      // 顶部搜索框是否展开
      showLogin: true
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.user.baseInfo.uid;
    },
    isInnerIP() {
      return this.$store.state.user.isInnerIP;
    },
    userInfoGet() {
      return this.$store.state.user.userInfoGet;
    },
    user() {
      return Object.assign(this.$store.state.user.baseInfo, {
        isBigVUser: 1
      });
    },
    isPrivacyStatementVerified() {
      return this.$store.state.user.baseInfo.isPrivacyStatementVerified;
    }
  },
  watch: {
    isPrivacyStatementVerified() {
      this.checkIsSignPrivacy();
    },
    userInfoGet: {
      handler(val) {
        if (val && !this.isLogin) {
          this.loadBuryCode();
          if (this.isInnerIP) {
            jumpUniportal.toUniportal(this.$route.query.backUrl, window.location.href);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.isError) {
      this.$store.dispatch('refreshRefactorUser', {
        isError: true,
        route: this.$route
      });
    } else {
      this.$store.dispatch('refreshRefactorUser', {
        route: this.$route
      });
    }
  },
  mounted() {
    // 国内版移动端访问H5
    if (isMobile()) {
      const h5Url = this.getH5Url();
      if (h5Url !== '') {
        if (this.hasFlag()) {
          APPUtil.goToMobile('finishPage', {}, this.$route);
        }
        window.open(h5Url, '_self');
      }
    }
    if (this.isEnJdc) {
      // 海外登录页隐藏登录按钮
      const pathname = location.pathname;
      const hideFooterArr = ['/jdc/refactor/login'];
      this.showLogin = !hideFooterArr.includes(pathname);
    }
    this.checkIsSignPrivacy();
  },
  methods: {
    /**
     * 存在sessionStorage一个标识，判断是否第一次从web url跳转h5 url
     */
    hasFlag() {
      if (sessionStorage.getItem(APP_LINK_BACK_FLAG)) {
        sessionStorage.removeItem(APP_LINK_BACK_FLAG);
        return true;
      } else {
        sessionStorage.setItem(APP_LINK_BACK_FLAG, 'need-finish');
        return false;
      }
    },
    // 使用移动设备访问，跳转至H5页面
    getH5Url() {
      const str = window.location.href;
      const uriArr = str.split('/');
      const uri = uriArr[uriArr.length - 1].toLowerCase();
      // 我的圈子
      if (/group/.test(uri)) {
        return '/h5/group';
      }
      let index = str.indexOf('/jdc/'); // 字符出现的位置
      let num = 0;
      while (index !== -1) {
        num++; // 每出现一次 次数加一
        index = str.indexOf('f', index + 1); // 从字符串出现的位置的下一位置开始继续查找
      }
      const temp2 = str.split('?')[1];
      const pram2 = new URLSearchParams(`?${temp2}`);
      // 版块/圈子列表
      if (/group\?groupid=/.test(uri)) {
        // JDC的1039和1041是一级板块，下面没有帖子，手机浏览器访问这两个板块时不允许跳转到H5，否则会读取不到数据
        if (num < 1 && (!str.indexOf(1039) || !str.indexOf(1041))) {
          return `/h5/groupDetail?fid=${pram2.get('groupId')}`;
        }
      }
      // 帖子详情
      if (/viewthread\?tid=/.test(uri)) {
        return `/h5/postDetail?tid=${pram2.get('tid')}`;
      }
      // 首页作为门面，必须"我家大门常打开，开放欢迎你"
      if (/portal/.test(uri) || uri === 'portal' || uri === '' || uri === '?appid=jdc' || uri === 'jdc') {
        return '/h5/';
      }
      // 板块详情和圈子详情页
      if (/viewforum\?fid=/.test(uri)) {
        return `/h5/groupDetail?fid=${pram2.get('fid')}`;
      }
      // 隐私协议与用户声明
      if (/privacystatement\?status=/.test(uri)) {
        if (pram2.get('status') === 'declares') {
          return `/h5/privacy?btn=hide`;
        }
        if (pram2.get('status') === 'agreement') {
          return `/h5/userAgreement?btn=hide`;
        }
      }
      // 抽奖页面
      const currentRoute = uriArr[uriArr.length - 2].toLowerCase();
      const lotteryId = uriArr[uriArr.length - 1];
      if (/lottery/.test(currentRoute)) {
        return `/h5/lottery/${lotteryId}`;
      }
      return '';
    },
    checkIsSignPrivacy() {
      if (this.isPrivacyStatementVerified === 0 && window.location.pathname !== '/jdc/refactor/privacyStatement') {
        window.open(`/jdc/refactor/privacyStatement?redirect=${encodeURIComponent(window.location.href)}`, '_self');
      }
    },
    // 搜索框展开状态更改
    changeSearch(val) {
      this.searchOpen = val;
    },
    loadBuryCode() {
      const {
        name
      } = this.$route;
      const pageCode = this.getPageCode(name);
      if (pageCode) {
        this.$store.dispatch('buryCode', pageCode);
      }
    },
    getPageCode(name) {
      const {
        Certified,
        Chat,
        ColumnDetail,
        Column,
        ColumnPost,
        Group,
        Mall,
        Search,
        Trade,
        NewThread,
        Portal,
        Viewthread,
        ContactList,
        MyLevel,
        MySmartBean,
        MyTask,
        PostList,
        Profile,
        Sign
      } = this.$rm.pageCode;
      const pageCodeObj = {
        Certified,
        Chat,
        ColumnDetail,
        Column,
        ColumnPost,
        Group,
        Mall,
        Search,
        Trade,
        NewThread,
        Portal,
        Viewthread,
        ContactList,
        MyLevel,
        MySmartBean,
        MyTask,
        PostList,
        Profile,
        Sign
      };
      return pageCodeObj[name] || '';
    }
  }
};