import { enAboutUs, enOuterLinks } from '@oc/masterPage/constants';
import EnFooter from '@oc/masterPage/Footer.vue';
import en from 'element-plus/dist/locale/en.mjs';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { defineAsyncComponent } from 'vue';
import CookiePolicy from '@/components/common/cookiePolicy/CookiePolicy.vue';
import BackTopOrBottom from '@/components/plugins/element/BackTopOrBottom';
import Breadcrumb from '@/components/plugins/element/Breadcrumb';
import LatestUserMedalDialog from '@/components/refactorJdc/common/LatestUserMedalDialog.vue';
import JDCFooter from '@/components/refactorJdc/Footer';
import JDCHeader from '@/components/refactorJdc/header/Header';
import env from '@/config/env.js';
import { FIRST_LOGIN } from '@/store/mutation-types.js';
import * as CommonUtils from '@/utils/common/CommonUtils';
import routerUtils from '@/utils/common/RouterUtils';
import { IS_EN_JDC } from '@/utils/web/constant';
const FULL_WIDTH = 960; //屏幕宽度

export default {
  name: 'Index',
  components: {
    JDCHeader,
    JDCFooter,
    EnFooter,
    Breadcrumb,
    BackTopOrBottom,
    LatestUserMedalDialog,
    CompleteInfo: defineAsyncComponent(() => import('@/components/refactorJdc/completeInfo/Index')),
    CookiePolicy
  },
  data() {
    return {
      IS_EN_JDC,
      fullWidth: document.documentElement.clientWidth,
      isShow: false,
      tokenTimer: null,
      tokenInfo: {
        duration: 0,
        // token有效期
        isToken: false // 请求token接口完成
      },
      completeInfoShow: false,
      locale: IS_EN_JDC ? en : zhCn,
      logoImg: require('@/assets/images/portal/beans_dark.png'),
      enAboutUs,
      enOuterLinks,
      showFooter: true,
      wxLoginScript: null
    };
  },
  computed: {
    app() {
      return this.$store.state.router.app;
    },
    updatedInfo() {
      return this.$store.state.common.updatedInfo;
    },
    uid() {
      return this.$store.state.user.baseInfo?.uid;
    },
    //是否显示上下滚动箭头
    isShowBackTop() {
      return !(this.fullWidth < FULL_WIDTH && this.findPath('/jdc/column') || this.findPath('/jdc/videoPlay') || this.findPath('/jdc/recommendationPage')) && this.tokenInfo.isToken;
    }
  },
  watch: {
    updatedInfo: {
      handler(val) {
        if (!IS_EN_JDC) {
          this.completeInfoShow = val;
          return;
        }
        // 海外JDC需要完善用户信息时，需要跳转到完善用户信息页面
        if (val) {
          routerUtils.toCompletePages();
        }
      },
      deep: true,
      immediate: true
    },
    $route(val) {
      // 监听路由
      this.checkPathname();
      const code = val.query?.code;
      const state = val.query?.state;
      // 微信登陆
      if (code && state && !this.baseinfo?.uid) {
        const url = this.$rm.api.refactorApi.user.loginFromWeChatWeb;
        this.$rm.api.GET(url, {
          code
        }, env.newApiBaseURL).then(res => {
          if (res.code === this.$rm.errCode.success) {
            const redrectUrl = location?.href?.replace(`code=${code}`, '')?.replace(`&state=${state}`, '');
            window.open(redrectUrl, '_self');
          }
        });
      }
    }
  },
  created() {
    window.setCurrentClient = function (client) {
      window.currentClient = client;
    };
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf('androidNative') > -1) {
      window.currentClient = 'Android';
    } else if (userAgent.indexOf('iosNative') > -1) {
      window.currentClient = 'iOS';
    }
    this.checkPathname();
    this.$store.dispatch('getApp', window.location.href);
    this.$store.dispatch('getHWConnect', window.location.href);
    this.$store.dispatch('getLang', window.location.href, localStorage.getItem('lang'));
    localStorage.setItem('lang', this.$store.state.router.lang);
    this.firstLogin();
    if (this.isEnJdc) {
      // 海外版需要兼容苹果浏览器
      return;
    }
    // 如果是苹果浏览器并且非移动端，跳转到ios不兼容页面
    if (!CommonUtils.isMobile() && CommonUtils.isIOS() && !location.href.includes('/jdc/refactor/ioserror')) {
      this.$router.push({
        path: '/refactor/ioserror'
      });
    }
  },
  mounted() {
    //微信登录引用js资源
    this.wxLoginScript = document.createElement('script');
    this.wxLoginScript.src = env.wxloginUrl;
    document.head.appendChild(this.wxLoginScript);
  },
  unmounted() {
    document.head.removeChild(this.wxLoginScript);
    this.wxLoginScript = null;
  },
  methods: {
    firstLogin() {
      const url = this.$rm.api.refactorApi.user.login;
      this.$rm.api.POST(url, null, env.newApiBaseURL).then(res => {
        if (res.code === this.$rm.errCode.apiNoLogin && res.message === 'Not logon') {
          this.$store.commit(FIRST_LOGIN, false);
          this.tokenInfo.isToken = true;
        } else {
          this.$store.commit(FIRST_LOGIN, true);
          this.getToken();
        }
      }).catch(() => {});
    },
    getToken() {
      this.$rm.api.getToken().finally(() => {
        this.tokenInfo.isToken = true;
      });
    },
    checkPathname() {
      const pathname = location.pathname;
      const pathArr = ['/jdc/refactor/videoPlay', '/jdc/refactor/recommendationPage', '/jdc/refactor/shield', '/jdc/refactor/avatar', '/jdc/refactor/cross', '/jdc/refactor/user/pool', '/jdc/refactor/overSeasComplete'];
      if (!pathArr.includes(pathname) && (this.fullWidth >= FULL_WIDTH || !pathname.includes('/jdc/column'))) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      const hideFooterArr = ['/jdc/refactor/login', '/jdc/refactor/privacyStatement'];
      this.showFooter = !hideFooterArr.includes(pathname);
    },
    findPath(path = '') {
      return this.$route.path.includes(path);
    },
    // 点击其它区域顶部搜索栏关闭
    headerSearchClose() {
      if (!this.isEnJdc) {
        this.$refs.header.searchOpen = false;
      }
    }
  }
};