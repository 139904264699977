export default {
  name: 'Breadcrumb',
  props: {
    limit: Number
  },
  computed: {
    items() {
      return this.$store.state.router.items;
    },
    hasRouter() {
      return this.items && this.items.length > 1;
    }
  },
  methods: {
    limitTextLength(text, limit) {
      if (!text) {
        return text;
      }
      if (text && text.length <= limit) {
        return text;
      }
      return `${text.substr(0, limit)}...`;
    },
    limitTitle(title, limit) {
      if (!title) {
        return title;
      }
      if (title && title.length <= limit) {
        return '';
      }
      return title;
    }
  }
};