import { createWebHistory, createRouter } from 'vue-router';

/*
 * @Author:
 * @Date: 2022-07-18 19:40:42
 * @Description:重构前端路由
 */
import enJdcRoutes from '@/router/enJdcRoutes';
import { isMobile } from '@/utils/common/CommonUtils';
import RouterUtils from '@/utils/common/RouterUtils';
import { buriedCode } from '@/utils/hwa';
import { IS_EN_JDC, isBuriedPageCode } from '@/utils/web/constant';

const base = '/jdc';

const RefactorGroup = () => import('@/components/refactorJdc/group/GroupNew.vue');
const RefactorNewThread = () => import('@/components/refactorJdc/newThread/newThread.vue');
const ReleaseOriginalReport = () => import('@/components/refactorJdc/originalReport/releaseReport/ReleaseOriginalReport.vue');
const RefactorViewForum = () => import('@/components/refactorJdc/forum/forum.vue');
const RefactorForumManage = () => import('@/components/refactorJdc/circleManage/Common.vue');
const RefactorFindUser = () => import('@/components/refactorJdc/findUser/findUser.vue');
const RefactorViewthread = () => import('@/components/refactorJdc/postDetails/postDetails.vue');
const RefactorShield = () => import('@/components/refactorJdc/common/Shield.vue');
const RefactorPotal = () => import('@/components/refactorJdc/portal/portal.vue');
const RefactorProfile = () => import('@/components/refactorJdc/profile/Profile.vue');
const RefactorMyTask = () => import('@/components/refactorJdc/profile/MyTask.vue');
const RefactorContactList = () => import('@/components/refactorJdc/profile/ContactList.vue');
const RefactorMyLevel = () => import('@/components/refactorJdc/profile/MyLevel.vue');
const RefactorPostList = () => import('@/components/refactorJdc/profile/PostList.vue');
const RefactorMall = () => import('@/components/refactorJdc/mall/Mall.vue');
const RefactorChat = () => import('@/components/refactorJdc/chat/Chat.vue');
const RefactorSearch = () => import('@/components/refactorJdc/mall/Search');
const RefactorTrade = () => import('@/components/refactorJdc/mall/Trade');
const RefactorMySmartBean = () => import('@/components/refactorJdc/profile/MySmartBean');
const RefactorCertified = () => import('@/components/refactorJdc/certifiedInfo/CertifiedInfo.vue');
const RefactorPrivacyStatement = () => import('@/components/refactorJdc/common/PrivacyStatement');
const RefactorNotice = () => import('@/components/refactorJdc/notice/Notice');
const Sign = () => import('@/components/refactorJdc/sign/Sign');
const RefactorActivity = () => import('@/components/refactorJdc/activity/Activity.vue');
const RefactorPreviewPost = () => import('@/components/refactorJdc/newThread/commonTemplate/previewPost.vue');
const RefactorSettings = () => import('@/components/refactorJdc/settings/settings.vue');
const RefactorLottery = () => import('@/components/common/lottery/lottery/refactorLottery');
const RefactorCreateCircle = () => import('@/components/refactorJdc/circleManage/CreateCircle.vue');
const RefactorTagPage = () => import('@/components/refactorJdc/tagPage/tagPage.vue');
const RefactorCross = () => import('@/components/refactorJdc/portal/Cross.vue');
const IOSErrorPage = () => import('@/components/common/IOSErrorPage.vue');
const ReportMain = () => import('@/components/refactorJdc/report/index.vue');

// needLogin 当前页面是否需要登录
const routes = [
  {
    path: RouterUtils.baseRoute('/refactor/reportMain'),
    name: 'ReportMain',
    component: ReportMain,
    meta: { needLogin: false, pageCode: 'P7999A8DA38BA69' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/ioserror'),
    name: 'IOSErrorPage',
    component: IOSErrorPage,
    meta: { needLogin: false, pageCode: 'PDD40C22CAF2A29' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/group'),
    name: 'Group',
    component: RefactorGroup,
    meta: { needLogin: false, pageCode: 'PDD40C22CAF2A29' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/newThread'),
    name: 'NewThread',
    component: RefactorNewThread,
    meta: { needLogin: true, pageCode: 'PC66C3458C0ACEF' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/releaseOriginalReport'),
    name: 'ReleaseOriginalReport',
    component: ReleaseOriginalReport,
    meta: { needLogin: true, pageCode: 'P95BDCF04657D33' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/viewForum'),
    name: 'viewForum',
    component: RefactorViewForum,
    meta: {
      needLogin: false,
      pageCodes: {
        flag: {
          suggest: 'PA9399C8A27BD57', // 提产品建议
          askpeers: 'P5817D7A0DDD9BE', // 问问同行
          stationService: 'PDD40DBED224B13', // 站务
          interactionsuggest: 'P57FA435B1CE5F9', // 讨论
          discuss: 'P57FA55EB4662E4', // 精华讨论
          originalReport: 'P87FB3683A95E1B', // 原创报告
          openClass: 'P573E0D4890645F', // 公开课
          default: 'PDD40D40B741DFD', // 无flag 默认无圈子详情
        },
      },
    },
  },
  {
    path: RouterUtils.baseRoute('/refactor/forumManage'),
    name: 'ForumManage',
    component: RefactorForumManage,
    meta: { needLogin: false, pageCode: 'P57F9B63AD3ED9F' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/findUser'),
    name: 'FindUser',
    component: RefactorFindUser,
    meta: { needLogin: true, pageCode: 'PC66C3AA7DF24BE' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/viewthread'),
    name: 'Viewthread',
    component: RefactorViewthread,
    meta: { needLogin: false, pageCode: 'PA293B5C4B0281A' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/shield'),
    name: 'Shield',
    component: RefactorShield,
    meta: { needLogin: false, pageCode: 'P5CB2636F91787C' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/portal'),
    name: 'Portal',
    component: RefactorPotal,
    meta: { needLogin: false, pageCode: 'PDD40A17A3BEE7B' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile'),
    name: 'Profile',
    component: RefactorProfile,
    meta: { needLogin: true, pageCode: 'PC66C3F7388897C' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile/task'),
    name: 'MyTask',
    component: RefactorMyTask,
    meta: { needLogin: true, pageCode: 'PC66CA361AC7F90' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile/contact'),
    name: 'ContactList',
    component: RefactorContactList,
    meta: { needLogin: true, pageCode: 'PA5E5FE45960FD6' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile/level'),
    name: 'MyLevel',
    component: RefactorMyLevel,
    meta: { needLogin: true, pageCode: 'PC66CA586E1E006' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile/thread'),
    name: 'PostList',
    component: RefactorPostList,
    meta: { needLogin: true, pageCode: 'PC66C9CCE359228' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/mall'),
    name: 'Mall',
    component: RefactorMall,
    meta: { needLogin: false, pageCode: 'PDD40E1B73E0799' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/chat'),
    name: 'Chat',
    component: RefactorChat,
    meta: { needLogin: true, pageCode: 'PC66D2293361580' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/mall/search'),
    name: 'Search',
    component: RefactorSearch,
    meta: { needLogin: true, pageCode: 'PC66C1A020FC53E' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/mall/trade'),
    name: 'Trade',
    component: RefactorTrade,
    meta: { needLogin: true, pageCode: 'PC66C2AC719A970' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/profile/credit'),
    name: 'MySmartBean',
    component: RefactorMySmartBean,
    meta: { needLogin: false, pageCode: 'PC66CA0CDE99C6C' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/certifiedInfo/:certifiedId'),
    name: 'Certified',
    component: RefactorCertified,
    meta: { needLogin: false, pageCode: 'PA5E606D660128E' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/privacyStatement'),
    name: 'PrivacyStatement',
    component: RefactorPrivacyStatement,
    meta: { needLogin: false, pageCode: 'PC66C98C03F9ADF' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/notice'),
    name: 'Notice',
    component: RefactorNotice,
    meta: { needLogin: true, pageCode: 'PC66D32190700F8' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/sign'),
    name: 'Sign',
    component: Sign,
    meta: { needLogin: true, pageCode: 'PC66CA8531B37A0' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/previewThread'),
    name: 'PreviewThread',
    component: RefactorPreviewPost,
    meta: { needLogin: false, pageCode: 'P57F8DCD3525274' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/settings'),
    name: 'Settings',
    component: RefactorSettings,
    meta: { needLogin: true, pageCode: 'PC66C53EAF49AAF' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/activity'),
    name: 'Activity',
    component: RefactorActivity,
    meta: { needLogin: false, pageCode: 'P26E754DC429BF1' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/lottery/:id'),
    name: 'Lottery',
    component: RefactorLottery,
    meta: { needLogin: false, pageCode: 'P57FDD0CCF2C42C' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/createCircle'),
    name: 'CreateCircle',
    component: RefactorCreateCircle,
    meta: { needLogin: false, pageCode: 'P57FD09ED2E0DE1' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/tagPage'),
    name: 'TagPage',
    component: RefactorTagPage,
    meta: { needLogin: false, pageCode: 'P57FD8AACC3AFC0' },
  },
  {
    path: RouterUtils.baseRoute('/refactor/cross'),
    name: 'Cross',
    component: RefactorCross,
    meta: { needLogin: false, pageCode: 'P45E0D0B2CA6236' },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/refactor/portal',
    meta: { needLogin: false },
  },
];

const router = createRouter({
  history: createWebHistory(base), // 去掉锚点
  routes: IS_EN_JDC ? enJdcRoutes : routes,
});

router.beforeEach((to, from, next) => {
  next(vx => {
    vx?.$store?.dispatch('updateWeChatPage', {
      page: 'pages/index/index',
    });
  });
});

router.beforeEach(to => {
  // 海外版移动端访问web侧首页跳转到H5首页
  if (isMobile() && IS_EN_JDC && to.name === 'EnPortal') {
    window.open('/h5/', '_self');
  }
});

router.afterEach(to => {
  // 同意cookie后 再埋码
  if (isBuriedPageCode()) {
    buriedCode(to);
  }
});
export default router;
