import env from '@/config/env.js';
function getFullUrl(domain) {
  return `https://${domain}`;
}
const currentEnv = env.currentEnv;
const prodLink = {
  skillSurport: getFullUrl('support.huawei.com/carrierindex/zh/anony/index.html'),
  abountHW: getFullUrl('www.huawei.com/cn/about-huawei/'),
  vfSiderbar: getFullUrl('partner.huawei.com/university/webui/corpSpace/corpSpace.html'),
  toreport: getFullUrl('report.12377.cn:13225/toreportinputNormal_anis.do'),
  msHw: getFullUrl('3ms.huawei.com/km/groups/3757367/blogs/details/5911037'),
  viewthreadMall: '/jdc/refactor/viewthread?tid=417575',
  huaweiCom: getFullUrl('www.huawei.com'),
  huaweils: getFullUrl('cn.huaweils.com'),
  huaweiForum: getFullUrl('forum.huawei.com'),
  support: getFullUrl('support.huawei.com/carrier/'),
  enterprise: getFullUrl('forum.huawei.com/enterprise/index.html'),
  supportEnterprise: getFullUrl('support.huawei.com/enterprise/'),
  bbs: getFullUrl('bbs.huaweicloud.com/'),
  eHuawei: getFullUrl('e.huawei.com'),
  uniportal: getFullUrl('uniportal.huawei.com/uniportal/deregister.do?actionFlag=toAccountDeregister&lang=zh_CN'),
  portal: '/jdc',
  recordal: getFullUrl('beian.miit.gov.cn/#/Integrated/index'),
};
function webLink(item) {
  let link = prodLink[item];
  if (currentEnv !== 'prod') {
    link = '/jdc/refactor/portal';
  }
  return link;
}
export { webLink, prodLink };
