import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/header/en_jdc_logo.png';
import _imports_1 from '@/assets/images/header/logo.png';
const _withScopeId = n => (_pushScopeId("data-v-4477df64"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "headerContainer clearfix"
};
const _hoisted_2 = {
  href: "/jdc/refactor/portal",
  class: "logo"
};
const _hoisted_3 = {
  key: 0,
  class: "en-jdc-img",
  src: _imports_0,
  alt: "HUAWEI JDC"
};
const _hoisted_4 = {
  key: 1,
  width: "128",
  height: "36",
  src: _imports_1,
  alt: "HUAWEI JDC"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MenuList = _resolveComponent("MenuList");
  const _component_SearchBlock = _resolveComponent("SearchBlock");
  const _component_MobileTag = _resolveComponent("MobileTag");
  const _component_LoginBlock = _resolveComponent("LoginBlock");
  return _openBlock(), _createElementBlock("div", {
    class: "header-jdc",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => $data.searchOpen = false, ["stop"]))
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("a", _hoisted_2, [$data.IS_EN_JDC ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4))]), _createVNode(_component_MenuList, {
    "search-open": $data.searchOpen
  }, null, 8, ["search-open"]), !$data.IS_EN_JDC ? (_openBlock(), _createBlock(_component_SearchBlock, {
    key: 0,
    "is-open": $data.searchOpen,
    onChangeSearch: $options.changeSearch
  }, null, 8, ["is-open", "onChangeSearch"])) : _createCommentVNode("", true), !$data.IS_EN_JDC ? (_openBlock(), _createBlock(_component_MobileTag, {
    key: 1
  })) : _createCommentVNode("", true), $data.showLogin ? (_openBlock(), _createBlock(_component_LoginBlock, {
    key: 2
  })) : _createCommentVNode("", true)])]);
}