import env from '@/config/env';

export const aboutUs = `OceanClub is a worldwide technical community committed to fostering the exchange of data storage technologies, knowledge sharing, and collaborative problem-solving. `;

// JDC海外版About Us内容
export const enAboutUs = `Joint Definition Community (JDC) is a
  community platform provided by Huawei. JDC
  gives up to nearly 900,000 users and partners
  a platform to question, discuss, and
  collaborate with Huawei product experts on
  next-gen products.`;

export const outerLinks = {
  'Data Storage Website': env.dataStorageWebsite,
};

export const enOuterLinks = {
  'Huawei Corporate': env.huaweiUrl,
  'Enterprise Technical Support': env.supportHuawei,
  'Enterprise Forum': env.forumHuawei,
  'Huawei Cloud Forum': env.huaweiCloud,
};

export const menus = [
  { name: 'Home', path: 'portal' },
  { name: 'Events', path: 'events' },
  { name: 'Community', path: `${env.overseaJDCURL}/jdc/refactor/viewForum?fid=${env.defaultCircleId}`, type: 'outerLink' },
  { name: 'About Us', path: 'aboutUs' },
];
